import Promise from 'promise-polyfill';

const cachedResponses: { [key: string]: EmailValidationResult } = {};

export function getSuggestions(emailInput: HTMLInputElement, removeValidationClass: () => void): Promise<{ isWarning: boolean }> {
    const emailSuggestionElement = document.getElementById('emailSuggestion') as HTMLSpanElement;
    if (!emailSuggestionElement) {
        console.error('Could not find emailSuggestionElement span');
        return Promise.resolve({ isWarning: false });
    }

    emailSuggestionElement.textContent = '';
    const email = emailInput.value;

    if (cachedResponses.hasOwnProperty(email)) {
        return new Promise((resolve) => {
            resolve(handleResult(cachedResponses[email]));
        });
    }

    return fetch('/register/checkEmail', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            emailAddress: email
        })
    })
        .then((response) => response.json())
        .then((result: EmailValidationResult) => {
            cachedResponses[email] = result;
            return handleResult(result);
        });

    function handleResult(result: EmailValidationResult) {
        if (EmailValidationStatus[result.status] == EmailValidationStatus[EmailValidationStatus.DELIVERABLE]) {
            return { isWarning: false };
        }

        if (result.suggestion) {
            const message = emailSuggestionElement.getAttribute('data-didYouMean');
            displaySuggestion(result, message);

            return { isWarning: true };
        } else if (EmailValidationStatus[result.status] == EmailValidationStatus[EmailValidationStatus.UNDELIVERABLE]) {
            emailSuggestionElement.textContent = emailSuggestionElement.getAttribute('data-areYouSure');

            return { isWarning: true };
        }

        return { isWarning: false };
    }

    function displaySuggestion(result: EmailValidationResult, message: string | null) {
        const link = document.createElement('button');
        link.innerText = result.suggestion;
        link.onclick = () => {
            emailInput.value = result.suggestion;
            emailSuggestionElement.textContent = '';
            removeValidationClass();
        };
        const textContent = document.createTextNode(message || '');
        const afterLinkText = document.createTextNode('?');
        emailSuggestionElement.textContent = '';
        emailSuggestionElement.appendChild(textContent);
        emailSuggestionElement.appendChild(link);
        emailSuggestionElement.appendChild(afterLinkText);
    }
}

interface EmailValidationResult {
    email: string;
    domain: string;
    localPart: string;
    suggestion: string;
    status: number;
}

enum EmailValidationStatus {
    UNKNOWN = 0,
    DELIVERABLE = 1,
    UNDELIVERABLE = 2,
    RISKY = 3
}
