import pL from 'js-regex-pl';

import { getSuggestions } from './emailSuggestions';

function validateRegistrationForm() {
    const showPasswordButton = document.getElementById('showPasswordButton') as HTMLButtonElement;
    const form = document.getElementById('registrationForm') as HTMLFormElement;
    const submitButton = document.getElementById('submit') as HTMLButtonElement;
    const email = document.getElementById('email') as HTMLInputElement;
    const firstName = document.getElementById('firstName') as HTMLInputElement;
    const lastName = document.getElementById('lastName') as HTMLInputElement;
    const phone = document.getElementById('phone') as HTMLInputElement;
    const password = document.getElementById('password') as HTMLInputElement;
    const privacyPolicyCheckbox = document.getElementById('PrivacyPolicyAccepted') as HTMLInputElement;
    let isEmailWithWarning = false;

    const errorClass = 'NewForm__Error';
    const warningClass = 'NewForm__Warning';
    const passwordIndicatorValidClass = 'NewForm__PasswordValidation--Valid';

    // https://emailregex.com
    const emailRegEx =
        // eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const textValidation = new RegExp(`^(?!.*[\u0400-\u04FF])[${pL} ,.'-]*$`);
    const phoneRegEx = new RegExp(`^(?!0{8})[0-9]{8}$`);

    const addValidationEvents = (input: HTMLInputElement, validateMethod: () => void) => {
        addBlurValidation(input, validateMethod);
        addLiveValidation(input, validateMethod);
    };

    function addBlurValidation(input: HTMLInputElement, validateMethod: () => void) {
        input?.addEventListener('blur', () => blurValidation(input, validateMethod));
    }

    function addLiveValidation(input: HTMLInputElement, validateMethod: () => void) {
        input?.addEventListener('input', () => liveValidation(input, validateMethod));
    }

    const blurValidation = (input: HTMLInputElement, validateMethod: () => void) => {
        validateMethod();
    };

    const liveValidation = (input: HTMLInputElement, validateMethod: () => void) => {
        if (input.dataset.liveValidate === 'true') {
            validateMethod();
        }
    };

    const validatePassword = (validateOnlyIndicators = false) => {
        const passwordLengthValidIndicator = document.getElementById('passwordLength');
        const passwordCapitalLetterValidIndicator = document.getElementById('passwordCapitalLetter');
        const passwordSmallLetterValidIndicator = document.getElementById('passwordSmallLetter');
        const passwordNumberValidIndicator = document.getElementById('passwordNumber');
        const value = password.value;
        let hasError = false;

        if (value.length < 8) {
            hasError = true;
            passwordLengthValidIndicator?.classList.remove(passwordIndicatorValidClass);
        } else {
            passwordLengthValidIndicator?.classList.add(passwordIndicatorValidClass);
        }

        if (!/\d/.test(value)) {
            hasError = true;
            passwordNumberValidIndicator?.classList.remove(passwordIndicatorValidClass);
        } else {
            passwordNumberValidIndicator?.classList.add(passwordIndicatorValidClass);
        }

        if (!/[A-Z]/.test(value)) {
            hasError = true;
            passwordCapitalLetterValidIndicator?.classList.remove(passwordIndicatorValidClass);
        } else {
            passwordCapitalLetterValidIndicator?.classList.add(passwordIndicatorValidClass);
        }

        if (!/[a-z]/.test(value)) {
            hasError = true;
            passwordSmallLetterValidIndicator?.classList.remove(passwordIndicatorValidClass);
        } else {
            passwordSmallLetterValidIndicator?.classList.add(passwordIndicatorValidClass);
        }

        if (hasError && !validateOnlyIndicators) {
            addValidationClass(password, errorClass);
        } else {
            removeValidationClass(password, errorClass);
        }
    };

    const validateEmail = () => {
        if (!emailRegEx.test(email.value) || email.value.length > 105) {
            removeValidationClass(email, warningClass);
            addValidationClass(email, errorClass);
        } else {
            removeValidationClass(email, errorClass);
            if (!isEmailWithWarning && email.getAttribute('data-validateDomain') === 'true') {
                getSuggestions(email, () => removeValidationClass(email, warningClass)).then((result) => {
                    if (result.isWarning) {
                        isEmailWithWarning = true;
                        addValidationClass(email, warningClass);
                    } else {
                        removeValidationClass(email, warningClass);
                    }
                });
            }
        }
    };

    const validateFirstName = () => {
        if (!textValidation.test(firstName.value.trim())) {
            addValidationClass(firstName, errorClass);
        } else {
            removeValidationClass(firstName, errorClass);
        }
    };

    const validateLastName = () => {
        if (!textValidation.test(lastName.value.trim())) {
            addValidationClass(lastName, errorClass);
        } else {
            removeValidationClass(lastName, errorClass);
        }
    };

    const validatePhone = () => {
        if (!phoneRegEx.test(phone.value) || phone.value.length !== 8) {
            addValidationClass(phone, errorClass);
        } else {
            removeValidationClass(phone, errorClass);
        }
    };

    const validatePrivacyPolicy = () => {
        if (!privacyPolicyCheckbox.checked) {
            privacyPolicyCheckbox.parentElement?.classList.add(errorClass);
        } else {
            privacyPolicyCheckbox.parentElement?.classList.remove(errorClass);
            if (isFormValid()) {
                submitButton.disabled = false;
            }
        }
    };

    const addValidationClass = (input: HTMLInputElement, validationClass: string) => {
        input.dataset.liveValidate = 'true';
        input.parentElement?.parentElement?.classList.add(validationClass);
    };

    const removeValidationClass = (input: HTMLInputElement, validationClass: string) => {
        input.parentElement?.parentElement?.classList.remove(validationClass);
        if (isFormValid()) {
            submitButton.disabled = false;
        }
    };

    const addFormSubmitValidation = () => {
        form?.addEventListener('submit', (e) => {
            validateRegistrationForm();
            if (!isFormValid()) {
                e.preventDefault();
                submitButton.disabled = true;
            }
        });
    };

    const validateRegistrationForm = () => {
        validateEmail();
        validateFirstName();
        validateLastName();
        validatePhone();
        validatePassword();
        validatePrivacyPolicy();
    };

    const isFormValid = () => {
        const errors = document.getElementsByClassName(errorClass);
        return !(errors.length > 0);
    };

    const addTogglePasswordVisibility = () => {
        showPasswordButton?.addEventListener('click', (e) => {
            e.preventDefault();
            if (password.type === 'password') {
                password.type = 'text';
                showPasswordButton.classList.add('NewForm__ShowPasswordButton--Active');
            } else {
                password.type = 'password';
                showPasswordButton.classList.remove('NewForm__ShowPasswordButton--Active');
            }
        });
    };

    addBlurValidation(email, validateEmail);
    addValidationEvents(firstName, validateFirstName);
    addValidationEvents(lastName, validateLastName);
    addValidationEvents(phone, validatePhone);
    password?.addEventListener('input', () => validatePassword(true));
    email?.addEventListener('input', () => (isEmailWithWarning = false));
    privacyPolicyCheckbox?.addEventListener('change', validatePrivacyPolicy);
    addFormSubmitValidation();
    addTogglePasswordVisibility();
}

validateRegistrationForm();
