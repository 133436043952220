(() => {
    const adminLoginButton = document.getElementById('AdminLogin');
    const loginHeader = document.getElementById('LoginHeader');
    let clicksLeftToShowAdminLogin = 3;
    let isCtrlClicked = false;

    const showLoginButton = () => {
        adminLoginButton?.classList.add('IdentityPage__AdministratorLogin--Visible');
    };

    if (adminLoginButton) {
        document.addEventListener('keydown', (e) => {
            if (e.key === 'Control' || e.metaKey) isCtrlClicked = true;
            if (e.shiftKey && isCtrlClicked) showLoginButton();
        });

        document.addEventListener('keyup', (e) => {
            if (e.key === 'Control' || e.metaKey) isCtrlClicked = false;
        });
    }

    if (loginHeader) {
        loginHeader.addEventListener('click', () => {
            clicksLeftToShowAdminLogin--;

            if (clicksLeftToShowAdminLogin <= 0) {
                showLoginButton();
            }
        });
    }
})();
